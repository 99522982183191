import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Privacy Policy`}</h2>
    <p>{`Peralysis Games respsects the privacy of our users. Thus, we do not collect or use any cookies. You also won't find cross-site trackers or other creepy things on our site.`}</p>
    <p>{`We may collect personal data, such as your name and email address, that you voluntarily provide when you reach out to us via email or social media.`}</p>
    <h3>{`Our Games`}</h3>
    <p>{`We may collect some personal data in our games, but we avoid it when we can.
Any data we collect is for analytical purposes so that we can improve player experience.`}</p>
    <h4>{`Player Data`}</h4>
    <p>{`Multiplayer features in our games rely on authentication services, such as Steam, Unity and Epic, to verify ownership and provide access to networking functionality.
We only collect data on an opt-in basis and player's can request to have any collected data deleted at anytime.`}</p>
    <h5>{`Data we collect`}</h5>
    <ul>
      <li parentName="ul">{`IP address`}</li>
      <li parentName="ul">{`Unqiue device ID`}</li>
      <li parentName="ul">{`IDFV (Apple ID for Vendor)`}</li>
      <li parentName="ul">{`Usage data, such as app launches, session durations and scores`}</li>
    </ul>
    <h5>{`How we use data we collect`}</h5>
    <p>{`Any data we collect is for analytical purposes so that we can improve player experience. We do not sell or share any information we collect.`}</p>
    <h4>{`Payment information`}</h4>
    <p>{`Some games may provide in-app purchases. In such cases, you will be sent to a trusted third party, such as Steam or PayPal, to complete your purchase. `}</p>
    <p>{`We do not directly process any payments and thus do not handle or collect payment information in any way.
However, we will collect some personal data for each transaction, such as your name, email adress, and Steam or Epic account ID.
This allows us to provide you with necessary support, such as refunds.`}</p>
    <h4>{`3rd Party services`}</h4>
    <p>{`Unity Privacy Policy: `}<a parentName="p" {...{
        "href": "https://unity.com/legal/game-player-and-app-user-privacy-policy"
      }}>{`https://unity.com/legal/game-player-and-app-user-privacy-policy`}</a></p>
    <p><a parentName="p" {...{
        "href": "/"
      }}>{`Return to homepage`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
import React from "react"
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import Privacy from "../components/privacy"

const PrivacyPolicy = () => (
  <Layout>
      <Privacy offset={0} factor={2} />
  </Layout>
)

export default PrivacyPolicy
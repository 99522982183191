/** @jsx jsx */
import { jsx } from "theme-ui"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
// @ts-ignore
import PrivacyMDX from "../sections/privacy"

const Privacy = ({ offset, factor = 1 }: { offset: number; factor?: number }) => (
  <div>
    <div 
      sx={{
        position: `absolute`,
        width: `full`,
        height: `full`,
        background: "divider",
        backgroundColor: "divider",
        clipPath: "polygon(0 16%, 100% 4%, 100% 82%, 0 94%)",
        zIndex: -1
      }}></div>
      <div 
        sx={{
          padding: [3, 4, 4, 5],
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
          justifyContent: `center`,
          zIndex: 50,
        }}
      >
        <div sx={{ width: [`full`, `full`, `full`, `full`, `full`, `2/3`], textAlign: `left` }}>
          <PrivacyMDX />
        </div>
      </div>
  </div>
)

export default Privacy
